/*
 * this should be removed after we switch to editor js
 *
 * have a look at `apps/app/src/styles/article-editor.css`
 */

.document-renderer {
  @apply mb-6 !font-normal;
}

.document-renderer:last-child {
  @apply !mb-0;
}

.document-renderer + p > strong {
  @apply !text-[28px] !leading-9;
}

blockquote {
  @apply !mb-6;
}

blockquote > p {
  @apply md:-ml-[22px] px-[22px] !font-secondary !italic !text-gray-700 border-l-4 border-gray-700;
}

.document-link {
  @apply text-green-600;
}

.document-link:hover {
  @apply text-orange-500 transition-colors duration-200;
}

.article-container {
  @apply !text-p2 md:!text-article font-secondary !text-gray-700 !font-normal;
}

.article-container ul li {
  @apply list-disc ml-[30px];
}

.article-container ul ul li {
  @apply list-[circle];
}

.article-container ul ul li:last-child {
  @apply mb-0;
}

.article-container li:last-child {
  @apply mb-6;
}
