.rnc__notification-item {
  box-shadow: none !important;
}

@media (max-width: 640px) {
  .rnc__notification-container--top-right {
    right: 0px !important;
    top: 0px !important;
  }
  .rnc__notification {
    width: 100vw !important;
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}
