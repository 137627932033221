.article__block {
  @apply mb-6 !font-normal;
}

.article__block b,
.article__block strong {
  @apply font-semibold;
}

.article__block:last-child {
  @apply !mb-0;
}

.article__h2 {
  @apply font-secondary font-semibold text-h2-m md:text-h2 not-italic;
}

.article__h3 {
  @apply font-secondary font-semibold text-h3-m md:text-h3 not-italic;
}

.article__h4 {
  @apply font-secondary font-semibold text-h4-m md:text-h4 not-italic;
}

.article__paragraph {
  @apply font-secondary !text-p2 md:text-article break-words;
}

.article__blockquote {
  @apply md:-ml-[22px] px-[22px] !font-secondary !italic !text-gray-700 border-l-4 border-gray-700;
}

.article__li {
  @apply ml-[30px] text-[18px];
}

.article__link {
  @apply underline text-green-600 break-words;
}

.article__link:hover {
  @apply text-orange-500 transition-colors duration-200;
}

.article__img {
  @apply m-auto;
}

.article__img-caption {
  @apply flex justify-center italic text-center text-gray-400 text-[14px];
  word-break: break-word;
  overflow-wrap: break-word;
}

.article__table {
  @apply m-4;
}

.article__table__data {
  @apply border border-gray-300 px-4 py-1;
}

.article__ol {
  counter-reset: item;
}

.article__ol .article__li:before {
  content: counters(item, '.') '. ';
  counter-increment: item;
}
