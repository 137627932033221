.article-editor-wrapper .ce-block__content,
.article-editor-wrapper .ce-toolbar__content {
  @apply max-w-full w-full;
}

.article-editor-wrapper .cdx-block,
.article-editor-wrapper .ce-header {
  @apply !pt-0 !pb-0;
}

.article-editor-wrapper .ce-block {
  @apply article__block;
}

.article-editor-wrapper h2.ce-header {
  @apply article__h2;
}

.article-editor-wrapper h3.ce-header {
  @apply article__h3;
}

.article-editor-wrapper h4.ce-header {
  @apply article__h4;
}

.article-editor-wrapper .ce-paragraph,
.article-editor-wrapper .cdx-nested-list {
  @apply article__paragraph;
}

.ce-paragraph[data-placeholder]::before {
  @apply !text-gray-400;
}

.article-editor-wrapper .cdx-quote .cdx-input {
  @apply border-0 p-0 shadow-none;
}

.article-editor-wrapper .cdx-quote {
  @apply article__blockquote;
}

.article-editor-wrapper li {
  @apply !mt-0 !mb-0 article__li;
}

.article-editor-wrapper .cdx-nested-list--unordered .cdx-nested-list--unordered li::before {
  content: '○';
}

.article-editor-wrapper a {
  @apply article__link;
}

.article-editor-wrapper img {
  @apply article__img;
}

.article-editor-wrapper .image-tool__caption {
  @apply border-0 p-0 shadow-none article__img-caption;
}

/* styles for image caption placeholder */
.article-editor-wrapper .image-tool__caption:empty:not(:focus)::before {
  @apply article__img-caption;
  /* if not added as separate @apply, it does not provide any effect */
  @apply !flex;
}
