/*
 * classes names are relevant here
 * apps/app/src/components/Highlight/constants.ts
 */

.highlight-container {
  @apply border-t2-primary-brandColor border-b-2 border-dashed hover:border-solid;
}

.highlight-container--hovered {
  @apply border-solid;
}
