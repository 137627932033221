/* IvarDisplay-regular */
@font-face {
  font-family: 'Ivar Display';
  font-style: normal;
  font-weight: 400;
  src: local(''), /* Super Modern Browsers */ url('../../public/fonts/ivar/IvarDisplay-Regular.woff') format('woff');
}

/* IvarDisplay-semi-bold */
@font-face {
  font-family: 'Ivar Display';
  font-style: normal;
  font-weight: 600;
  src: local(''), /* Super Modern Browsers */ url('../../public/fonts/ivar/IvarDisplay-SemiBold.woff') format('woff');
}

/* suisse Intl-light */
@font-face {
  font-family: 'Suisse Intl';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    /* Super Modern Browsers */ url('../../public/fonts/suisseIntl/SuisseIntl-Light-WebM.woff') format('woff');
}

/* suisse Intl-regular */
@font-face {
  font-family: 'Suisse Intl';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    /* Super Modern Browsers */ url('../../public/fonts/suisseIntl/SuisseIntl-Regular-WebM.woff') format('woff');
}

/* suisse Intl-semi-bold */
@font-face {
  font-family: 'Suisse Intl';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    /* Super Modern Browsers */ url('../../public/fonts/suisseIntl/SuisseIntl-SemiBold-WebM.woff') format('woff');
}

/* suisse Intl-black */
@font-face {
  font-family: 'Suisse Intl';
  font-style: normal;
  font-weight: 900;
  src: local(''),
    /* Super Modern Browsers */ url('../../public/fonts/suisseIntl/SuisseIntl-Black-WebM.woff') format('woff');
}
