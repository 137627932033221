.slick-arrow::before {
  @apply hidden;
}

.slick-track {
  @apply !m-auto;
}

.slick-slider {
  @apply mx-5;
}

.slick-disabled {
  @apply !hidden;
}

.slick-list {
  @apply -mx-[10px] -left-[2px] md:-left-[5px];
}

.slick-slide > div {
  @apply mx-[10px];
}

.slick-dots li {
  @apply !mx-[5px];
}

.slick-dots li button:before {
  @apply !text-[15px];
}

@media (max-width: 640px) {
  .slick-list {
    @apply -mx-[2px];
  }

  .slick-slide > div {
    @apply mx-[2px];
  }
  .territory-slick .slick-slide > div {
    @apply mx-[10px];
  }
}

.slick-list,
.slick-track {
  transition-delay: 10ms;
  @apply mx-auto;
}

.territory-slider {
  @apply !-bottom-10 opacity-50;
}

.slick-dots-hero {
  @apply !-bottom-10 opacity-50;
}

.slick-dots-leaderboard {
  @apply !-bottom-14 opacity-50;
}

@media (min-width: 768px) {
  .slick-track {
    @apply !flex;
  }
}
